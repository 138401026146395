import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import * as Yup from "yup";
import { useApi } from "../../services/helpers";
import pxToRem from "../../assets/theme/functions/pxToRem";
import { ROUTES } from "../../services/constants";
import {useEffect, useRef, useState} from "react";
import { Form, Formik, useFormikContext } from "formik";
import FormikInput from "../../components/FormikInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Radio } from "@mui/material";
import studentIcon from "../../assets/icons/student.svg";
import teacherIcon from "../../assets/icons/teacher.svg";
import SuccessfulRegistrationModal from "./SuccessfulRegistrationModal";



const ClickableCard = ({ imageSvg, isChecked, onClick, title }) => {
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={`card ${isChecked ? "checked" : ""}`}
      onClick={onClick}
    >
      {/*  eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      <img alt="Card Image" src={imageSvg} width={{xs:"25px", sm:'55px'}} />
      <MDTypography fontSize={14} color="black">
        {title}
      </MDTypography>
      <Radio checked={isChecked} />
    </MDBox>
  );
};

const SignupForm = () => {
  const formikRef = useRef();
  const api = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [teacher, setTeacher] = useState(false);

  const signup = (data) => {
    setTeacher(data.user_type !== "STUDENT")
    setLoading(true);
    api
      .sigup({
        name: data.name,
        email: data.email,
        school: data.school,
        password: data.password,
        grade: data.grade,
        is_student: data.user_type === "STUDENT",
      })
      .handle({
        onSuccess: () => {
          setShowModal(true)
        },
        errorMessage: "Error signing in",
        onFinally: () => setLoading(false),
      });
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(8, "Password must have at least 8 characters").required()
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("password")], "Passwords must match"),
    terms_and_conditions: Yup.boolean().when("step", {
      is: 1,
      then: Yup.boolean()
        .oneOf([true], "Please accept the terms and conditions")
        .required("Terms and conditions are required"),
      otherwise: Yup.boolean(),
    }),
    name: Yup.string().when("step", {
      is: 1,
      then: Yup.string().required("Name is required"),
      otherwise: Yup.string(),
    }),
    school: Yup.string().when("step", {
      is: 1,
      then: Yup.string().required("Name is required"),
      otherwise: Yup.string(),
    }),
    user_type: Yup.string().when("step", {
      is: 1,
      then: Yup.string().required("Name is required"),
      otherwise: Yup.string(),
    }),
    privacy_policy: Yup.boolean().when("step", {
      is: 1,
      then: Yup.boolean()
        .oneOf([true], "Please accept the privacy policy")
        .required("Privacy policy is required"),
      otherwise: Yup.boolean(),
    }),
  });

  const initialValues = {
    email: "",
    password: "",
    confirm_password: "",
    step: 0,
    terms_and_conditions: false,
    privacy_policy: false,
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => signup(values)}
    >
      {({ values, errors, isValid, setFieldValue, dirty }) => {
        return (
          <Form style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {values.step === 0 && <SignUpStep1 errors={errors} />}
            {values.step === 1 && <SignUpStep2 errors={errors} />}
            <MDBox
              mt={{xs:1, sm:5}}
              mb={1}
              textAlign="center"
              display="flex"
              justifyContent={"space-between"}
              gap={3}
              // mx={3}
              flexDirection="row"
            >
              <MDButton
                variant="contained"
                color="white"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(ROUTES.LOGIN);
                }}
                sx={{ width: pxToRem(280) }}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="contained"
                color="primary"
                loading={loading}
                disabled={loading || !isValid || !dirty}
                onClick={(e) => {
                  if (values.step === 1) {
                  } else {
                    e.preventDefault();
                    setFieldValue("step", values.step + 1);
                  }
                }}
                sx={{ width: pxToRem(280) }}
                type="submit"
              >
                {values.step === 0 ? "Continue" : "Sign Up"}
              </MDButton>
            </MDBox>
            <SuccessfulRegistrationModal
              showModal={showModal} teacher={teacher}
            />
          </Form>
        );
      }}

    </Formik>
  );
};

const SignUpStep2 = ({ errors }) => {
  const { setFieldValue, values } = useFormikContext();

  return (
      <MDBox display="flex" flexDirection="column" >
      <MDTypography fontSize={{xs:'16px', md:"18px"}} color="black" mt={3} textAlign="center">
        Please choose the type of account:
      </MDTypography>
      <MDBox sx={{ display: "flex", flexDirection: "row" }} gap={4} mt={3}>
        <MDBox flex={1}>
          <ClickableCard
            imageSvg={teacherIcon}
            title="Teacher"
            isChecked={values.user_type === "TEACHER"}
            onClick={() => setFieldValue("user_type", "TEACHER")}
          />
        </MDBox>
        <MDBox flex={1}>
          <ClickableCard
            imageSvg={studentIcon}
            title="Student"
            isChecked={values.user_type === "STUDENT"}
            onClick={() => setFieldValue("user_type", "STUDENT")}
          />
        </MDBox>
      </MDBox>
      <FormikInput name="name" label="Name" errors={errors} />
      <FormikInput name="school" label="School" errors={errors} />
      <FormikInput name="grade" label="Grade level" errors={errors} />

      <MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <FormikInput
          type="checkbox"
          name="terms_and_conditions"
          onChange={(value) => setFieldValue("terms_and_conditions", value.target.checked)}
          label={
            <MDTypography fontSize={14} color="black">
              I accept the{" "}
              <MDTypography
                component={Link}
                to={"#"}
                fontSize={14}
                color="primary"
                fontWeight="bold"
                onClick={() =>
                  window.open(ROUTES.USER_TERMS_AND_CONDITIONS, "_blank", "noopener,noreferrer")
                }
              >
                Terms and Conditions
              </MDTypography>
            </MDTypography>
          }
          errors={errors}
        />
        <FormikInput
          type="checkbox"
          name="privacy_policy"
          onChange={(value) => setFieldValue("privacy_policy", value.target.checked)}
          label={
            <MDTypography fontSize={14} color="black">
              I accept the{" "}
              <MDTypography
                component={Link}
                to={"#"}
                fontSize={14}
                color="primary"
                fontWeight="bold"
                onClick={() =>
                  window.open(ROUTES.USER_PRIVACY_POLICY, "_blank", "noopener,noreferrer")
                }
              >
                Privacy Policy
              </MDTypography>
            </MDTypography>
          }
          errors={errors}
        />
      </MDBox>
    </MDBox>
  );
};
const SignUpStep1 = ({ errors }) => {
  return (
    <>
      <FormikInput name="email" label="Email" type="email" errors={errors} />
      <FormikInput name="password" label="Set Password" type="password" errors={errors} />
      <FormikInput
        name="confirm_password"
        label="Confirm Password"
        type="password"
        errors={errors}
      />
    </>
  );
};
export default SignupForm;
