import {
    Box,
    Checkbox,
    Grid, IconButton,
    ToggleButton,
    ToggleButtonGroup, useTheme,
} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import ModalItem from "components/ModalItem";
import {Form, Formik} from "formik";
import React, {useEffect, useRef, useState} from "react";
import {useApi} from "../../services/helpers";
import * as Yup from "yup";
import MDTypography from "../../components/MDTypography";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "../../components/MDBox";
import {
    TYPE_OF_INTERACTION_OPTIONS,
    TYPE_OF_QUESTIONNAIRE_OPTIONS,
} from "../../utils/helpers";
import {MutatingDots} from "react-loader-spinner";
import googleIcon from "../../assets/icons/search.png";
import InfoIcon from '@mui/icons-material/Info';
import {useStores} from "../../models";
import {values} from "mobx";
import {SelectMenuProps} from "../../services/constants";
//

const TYPE_QUESTION_TOGGLE = {
    MULTIPLE: 2,
    OPEN: 3,
    EXPLAIN: 1,
};

const QUESTION_DIFFICULTY_TOGGLE = {
    ANY: null,
    EASY: 1,
    MEDIUM: 2,
    HARD: 3,
};

const DOK_LEVELS = [
    {id: true, name: "All"},
    {id: null, name: "None"},
    {id: 1, name: "[1] Recall"},
    {id: 2, name: "[2] Skill/Concept"},
    {id: 3, name: "[3] Strategic Thinking"},
    {id: 4, name: "[4] Extended Thinking"},
]

const IAQuestionModal = ({showModal, lessonSelected, handleClose, onAdd, courseSelected}) => {
    const api = useApi();
    const {ai_generator_grade_levels, setGradeLevels: rememberGradeLevels} = useStores();
    const formikRef = useRef();
    const [loading, setLoading] = useState(false);
    const [questionType, setQuestionType] = useState(TYPE_QUESTION_TOGGLE.MULTIPLE);
    const [questionDifficulty, setQuestionDifficulty] = useState(QUESTION_DIFFICULTY_TOGGLE.ANY);
    const [dokLevels, setDokLevels] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [usedQuestions, setUsedQuestions] = useState([]);
    const [blockGenerateButton, setBlockGenerateButton] = useState(false);
    const [gradeLevels, setGradeLevels] = useState([]);
    const [allGradeLevels, setAllGradeLevels] = useState([]);

    const validationSchema = Yup.object().shape({
        question_type: Yup.number().required("This field is required"),
        quantity: Yup.number().required("This field is required").min(1).max(8),
    });

    const initialValues = {
        question_type: TYPE_QUESTION_TOGGLE.MULTIPLE,
        quantity: 4,
    };

    // FAKE QUESTION DATA SAMPLES, here needs to be the api call
    const generateQuestions = (data, reset=false) => {
        if (reset) {
            setUsedQuestions([])
            setQuestionList([])
            setBlockGenerateButton(false)
        } else {
            rememberGradeLevels(gradeLevels)
            setLoading(true)
            setBlockGenerateButton(true)
            api.generateAiQuestions(courseSelected, {
                lesson: lessonSelected.id,
                prompt: data.description,
                question_type: questionType,
                responses: data.quantity,
                grade_levels: gradeLevels,
                dok_levels: dokLevels,
                difficulty: questionDifficulty,
                used_questions: usedQuestions,
            }).handle({
                onSuccess: (result) => {

                    const {response} = result;
                    const [questions, usage] = response;
                    console.log('tokens usage:', usage)

                    const qtype = questionType === TYPE_QUESTION_TOGGLE.MULTIPLE ? TYPE_OF_QUESTIONNAIRE_OPTIONS.MULTIPLE_CHOICE : TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN;
                    setQuestionList([...questionList, ...questions.map(q => {
                        let answers = data.question_type === TYPE_QUESTION_TOGGLE.EXPLAIN ? [] : q.answers.map(([answer, correct]) => ({
                            answer,
                            correct
                        }))
                        answers = questionType === TYPE_QUESTION_TOGGLE.MULTIPLE ? answers : answers.filter(({correct}) => correct)
                        return {
                            // id: null,
                            // timeout: null,
                            prompt: q.question,
                            question_type: qtype,
                            answer_type: TYPE_OF_INTERACTION_OPTIONS.BUTTONS,
                            answers: answers,
                            // reward: null,
                            // max_reward: 0,
                            randomize_answers: false,
                            // custom_order: null,
                        }
                    })]);
                    // console.log(response)
                },
                errorMessage: "Error generating questions",
                onError: (result) => {
                    console.log(result);
                    setBlockGenerateButton(false)
                    //formikRef.current.setErrors(result.errors)
                },
                onFinally: () => setLoading(false),
            })
        }

    }

    const handleDokChange = (event) => {
        if (event.target.value.includes(true)) {
            setDokLevels(DOK_LEVELS.map(({id}) => id).filter(id => id !== null && id !== true));
        } else if (event.target.value.includes(null)) {
            setDokLevels([]);
        }else {
            setDokLevels(event.target.value.sort());
        }
    }

    const handleGradesChange = (event) => {
        if (event.target.value.includes(true)) {
            setGradeLevels(allGradeLevels.map(({id}) => id).filter(id => id !== null && id !== true));
        } else if (event.target.value.includes(null)) {
            setGradeLevels([]);
        }else {
            setGradeLevels(event.target.value.sort());
        }
    }

    const handleChangeToggle = (event, questionType) => {
        if (questionType !== null) {
            setQuestionType(questionType);
        }
    };

    const handleChangeDifficultyToggle = (event, questionDifficulty) => {
        setQuestionDifficulty(questionDifficulty);
    }

    const handleRemoveQuestion = (index) => {
        setUsedQuestions([...usedQuestions, questionList[index].prompt])
        const newList = [...questionList];
        newList.splice(index, 1);
        setQuestionList(newList);
    }

    const handleAddQuestion = (index, questionData) => {
        handleRemoveQuestion(index)
        onAdd(questionData)
    }

    const handleSearchGoogle = (index, questionData) => {
        const url = `https://www.google.com/search?q=${questionData.prompt}`
        window.open(url, '_blank')
    }

    const handleModalClose = () => {
        setUsedQuestions([])
        setBlockGenerateButton(false)
        setQuestionList([])
        formikRef.current.resetForm()
        handleClose()
    }

    useEffect(() => {
        if (questionList?.length === 1 && formikRef.current) {
            generateQuestions(formikRef.current.values)
        }

    }, [questionList]);

    useEffect(() => {
        if (allGradeLevels && ai_generator_grade_levels) {
            const saved = values(ai_generator_grade_levels)
            const res = saved.filter((level) => !!allGradeLevels.find(({id}) => id === level))
            console.log(saved, allGradeLevels, res, )
            setGradeLevels(saved.filter((level) => !!allGradeLevels.find(({id}) => id === level)))
        }
    }, [allGradeLevels]);

    useEffect(() => {
        api.getGradeLevels().handle({
            onSuccess: ({response}) => { setAllGradeLevels([
                {id: true, name: "All"},
                {id: null, name: "None"},
                ...response
            ]) },
            errorMessage: "Error getting grade levels",
        })
    }, []);

    return (
        <ModalItem
            heightContainer={'100%'}
            minWidth={'80%'}
            widthContainer={'100%'}
            scrollable={false}
            open={showModal}
            closeOnClickOutside={false}
            height="80%"
            title="Generate Questions"
            handleClose={handleModalClose}
        >
            <MDBox sx={{width:'100%', padding: '20px'}}>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={(values) => generateQuestions(values, blockGenerateButton)}
            >
                {({errors, handleSubmit, setFieldValue}) => (
                    <Form>
                        <Grid container>
                            <Grid item container display={'flex'} margin={'auto'} flexDirection={'row'} wrap={'nowrap'}  justifyContent={'space-between'} xs={12} gap={6} mb={2}>
                                <Grid item container xs={8} flexDirection='column' wrap={'nowrap'} display={'flex'}>
                                    <Grid item xs={12}  md={12}>
                                        <MDTypography color="dark" mb={1} fontSize={{xs: "12px", md: "18px",}}>
                                            <strong>Lesson title:</strong> {lessonSelected?.name}
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <MDTypography color="dark" fontSize={{xs: "12px", md: "18px"}}>
                                            <strong>Explain what the lesson is about:</strong>
                                            <FormikInput
                                                type={"textarea"}
                                                name="description"
                                                errors={errors}
                                                fullWidth
                                                rows={4}
                                                disabled={blockGenerateButton}
                                            />
                                        </MDTypography>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <MDTypography
                                            color="dark"
                                            fontSize={{xs: "12px", md: "18px"}}
                                            fontWeight={"bold"}
                                            mb={1}
                                            sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}
                                        >
                                            Questions depth of knowledge:
                                            <IconButton color="primary" size={"small"} onClick={() => {
                                                const url = `https://barbarabray.net/2018/11/02/blooms-taxonomy-and-depth-of-knowledge-dok/`
                                                window.open(url, '_blank')
                                            }}>
                                                <InfoIcon />
                                            </IconButton>
                                        </MDTypography>
                                        <Select
                                            multiple
                                            value={dokLevels}
                                            onChange={handleDokChange}
                                            input={<OutlinedInput
                                                sx={[{
                                                    width: "100%",
                                                    minHeight: "45px",
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                    color: "#fff",
                                                    border: "1px solid rgb(219, 219, 219)",
                                                }]}
                                            />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={DOK_LEVELS.find(({id}) => id === value).name}
                                                            color={"primary"}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            fullWidth
                                            MenuProps={SelectMenuProps}
                                            style={{marginBottom: "5px"}}
                                        >
                                            {DOK_LEVELS.map((value) => (
                                                <MenuItem
                                                    key={value.name}
                                                    value={value.id}
                                                >
                                                    <Checkbox
                                                        checked={dokLevels.includes(value.id) ||
                                                            (value.id == null && dokLevels.length === 0) ||
                                                            (value.id === true && dokLevels.length === DOK_LEVELS.length - 2) } />
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <MDTypography
                                            color="dark"
                                            fontSize={{xs: "12px", md: "18px"}}
                                            fontWeight={"bold"}
                                            mb={1}
                                            sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}
                                        >
                                            Questions grade level/s:
                                        </MDTypography>
                                        <Select
                                            multiple
                                            value={gradeLevels}
                                            onChange={handleGradesChange}
                                            input={<OutlinedInput
                                                sx={[{
                                                    width: "100%",
                                                    minHeight: "45px",
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                    color: "#fff",
                                                    border: "1px solid rgb(219, 219, 219)",
                                                    padding: {
                                                        xs: '7px'
                                                    },
                                                }]}
                                            />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={allGradeLevels.find(({id}) => id === value).sn}
                                                            color={"primary"}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            fullWidth
                                            MenuProps={SelectMenuProps}
                                        >
                                            {allGradeLevels.map((value) => (
                                                <MenuItem
                                                    key={value.name}
                                                    value={value.id}
                                                >
                                                    <Checkbox
                                                        checked={gradeLevels.includes(value.id) ||
                                                            (value.id == null && gradeLevels.length === 0) ||
                                                            (value.id === true && gradeLevels.length === allGradeLevels.length - 2) } />
                                                    {value.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={4} flexDirection='column' wrap={'nowrap'} display={'flex'} >
                                    <Grid item xs={12} md={6} mt={1}>
                                        <MDTypography
                                            color="dark"
                                            fontSize={{xs: "12px", md: "18px"}}
                                            fontWeight={"bold"}
                                            mb={1}
                                        >
                                            Questions type:
                                        </MDTypography>
                                        <ToggleButtonGroup
                                            size="small"
                                            value={questionType}
                                            onChange={handleChangeToggle}
                                            exclusive
                                            color="primary"
                                            sx={{mb: "18px", }}

                                        >
                                            <ToggleButton
                                                value={TYPE_QUESTION_TOGGLE.MULTIPLE}
                                                key={TYPE_QUESTION_TOGGLE.MULTIPLE}
                                                disabled={blockGenerateButton}
                                            >
                                                Multiple Choice
                                            </ToggleButton>
                                            <ToggleButton
                                                value={TYPE_QUESTION_TOGGLE.OPEN}
                                                key={TYPE_QUESTION_TOGGLE.OPEN}
                                                disabled={blockGenerateButton}
                                            >
                                                Short answer
                                            </ToggleButton>
                                            <ToggleButton
                                                value={TYPE_QUESTION_TOGGLE.EXPLAIN}
                                                key={TYPE_QUESTION_TOGGLE.EXPLAIN}
                                                disabled={blockGenerateButton}
                                            >
                                                Open response
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>

                                    {questionType === TYPE_QUESTION_TOGGLE.MULTIPLE && (
                                        <Grid item xs={12} md={6} mt={1}>
                                            <MDTypography
                                                color="dark"
                                                fontSize={{xs: "12px", md: "18px"}}
                                                mb={1}
                                                fontWeight={"bold"}
                                            >
                                                Quantity of responses:
                                            </MDTypography>
                                            <FormikInput
                                                type={"number"}
                                                name="quantity"
                                                errors={errors}
                                                width={"60%"}
                                                defaultValue={4}
                                                disabled={blockGenerateButton}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={12} md={6} mt={1}>
                                        <MDTypography
                                            color="dark"
                                            fontSize={{xs: "12px", md: "18px"}}
                                            fontWeight={"bold"}
                                            mb={1}
                                        >
                                            Questions difficulty:
                                        </MDTypography>
                                        <ToggleButtonGroup
                                            size="small"
                                            value={questionDifficulty}
                                            onChange={handleChangeDifficultyToggle}
                                            exclusive
                                            color="primary"
                                            sx={{mb: "18px", }}

                                        >
                                            <ToggleButton
                                                value={QUESTION_DIFFICULTY_TOGGLE.ANY}
                                                key={QUESTION_DIFFICULTY_TOGGLE.ANY}
                                                disabled={blockGenerateButton}
                                            >
                                                Any
                                            </ToggleButton>
                                            <ToggleButton
                                                value={QUESTION_DIFFICULTY_TOGGLE.EASY}
                                                key={QUESTION_DIFFICULTY_TOGGLE.EASY}
                                                disabled={blockGenerateButton}
                                            >
                                                Easy
                                            </ToggleButton>
                                            <ToggleButton
                                                value={QUESTION_DIFFICULTY_TOGGLE.MEDIUM}
                                                key={QUESTION_DIFFICULTY_TOGGLE.MEDIUM}
                                                disabled={blockGenerateButton}
                                            >
                                                Medium
                                            </ToggleButton>
                                            <ToggleButton
                                                value={QUESTION_DIFFICULTY_TOGGLE.HARD}
                                                key={QUESTION_DIFFICULTY_TOGGLE.HARD}
                                                disabled={blockGenerateButton}
                                            >
                                                Hard
                                            </ToggleButton>

                                        </ToggleButtonGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!loading && <Grid item xs={12} mb={2}>
                                <MDButton
                                    color="primary"
                                    loading={loading}
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >
                                    {`${blockGenerateButton ? "Reset" : "Generate"}`}
                                </MDButton>
                            </Grid>}
                            <MDBox width={"100%"}>
                                {questionList.map((questionData, index) => (
                                    <MDBox bgColor={"#EEE"} p={3} mb={2}>
                                        <Grid item container xs={12} display={"flex"}>
                                            <Grid item xs={9}>
                                                <MDTypography color="dark" mb={1}
                                                              fontSize={{xs: "12px", md: "18px"}}>
                                                    <strong>Question: </strong> {questionData?.prompt}
                                                </MDTypography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <MDBox display={"flex"} justifyContent={"right"} gap={1}>
                                                    <MDButton color="white" onClick={() => handleSearchGoogle(index,questionData)}>
                                                        {/*<GoogleIcon color="primary"/>*/}
                                                        <MDBox component={'img'} src={googleIcon} alt="Search Google" height={'24px'}/>
                                                    </MDButton>
                                                    <MDButton color="white" onClick={() => handleAddQuestion(index,questionData)}>
                                                        <CheckIcon color="primary"/>
                                                    </MDButton>
                                                    <MDButton color="white"
                                                              onClick={() => handleRemoveQuestion(index)}>
                                                        <ClearIcon color="error"/>
                                                    </MDButton>
                                                </MDBox>
                                            </Grid>
                                        </Grid>

                                        {questionType !== TYPE_QUESTION_TOGGLE.EXPLAIN && (
                                            <Grid item xs={12}>
                                                <MDTypography
                                                    color="dark"
                                                    mb={1}
                                                    fontSize={{xs: "12px", md: "18px"}}
                                                    fontWeight={"bold"}
                                                >
                                                    Responses:
                                                </MDTypography>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            {questionType !== TYPE_QUESTION_TOGGLE.EXPLAIN &&
                                                questionData.answers?.map((answer, i) => (
                                                    <Box
                                                        display={"flex"}
                                                        gap={5}
                                                        justifyContent={"space-between"}
                                                        height={"29px"}
                                                    >
                                                        <Box display="flex" width={"60%"} alignItems="center">
                                                            <Checkbox checked={answer?.correct}/>
                                                            <MDTypography color="dark" sx={{p: 2}}>
                                                                {answer?.answer}
                                                            </MDTypography>

                                                        </Box>
                                                    </Box>
                                                ))}
                                        </Grid>
                                    </MDBox>
                                ))}
                            </MDBox>
                            <MDBox sx={{  width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                {loading && <Grid item xs={12} justifyContent={'center'} mb={2}>
                                    <MDBox sx={{  width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <MutatingDots width={100} height={100}/>
                                    </MDBox>

                                </Grid>}
                            </MDBox>
                        </Grid>
                    </Form>
                )}
            </Formik>
            </MDBox>
        </ModalItem>
    );
};

export default IAQuestionModal;
