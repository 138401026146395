import MDBox from "../../../components/MDBox";
import {Grid, Typography} from "@mui/material";
import {Form, Formik} from "formik";
import FormikInput from "../../../components/FormikInput";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDBuckPresetCard from "./MDBuckPresetCard";
import ModalItem from "../../../components/ModalItem";
import React, {useEffect, useRef, useState} from "react";
import * as Yup from "yup";
import {showMessage, useApi, useDebouncedInput} from "../../../services/helpers";
import DiscussionSuggestionsCard from "./DiscussionSuggestionsCard";
import Box from "@mui/material/Box";
import defaultImg from "../../../assets/images/default-profile.png";
import PaidIcon from "@mui/icons-material/Paid";

const RandomCallModal = ({ meetingId, showModal, handleClose, handleConfirm, plusBucksPresets, minusBucksPresets}) => {
  const formikRef = useRef()
  const api = useApi()
  const imageProfile = null //selectedItem?.image
  const [filter, setFilter] = useState('all');
  const [option, setOption] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)

  const getRandomCalling = () => {
    // setSelectedStudent(true)
    api.meetingRandomCalling(meetingId, {option}).handle({
      onSuccess: (response) => {
        setSelectedStudent(response?.data)
        formikRef.current.setValues({
          ...formikRef.current.values,
          student_id: response?.data?.id,
        })
        option === "reset" && showMessage("Reset successfully", 'success')
      },
      onFinally: () => setOption(null),
      errorMessage: 'Error while fetching random student ',
    })
  }

  const handleFilterClick = (filter) => {
    setFilter(filter);
  };

  const filteredSuggestions = () => {
    switch (filter) {
      case 'positive':
        return plusBucksPresets || [];
      case 'negative':
        return minusBucksPresets || [];
      case 'all':
      default:
        return [...plusBucksPresets, ...minusBucksPresets];
    }
  };


  useEffect(() => {
    if (option) getRandomCalling()
  }, [option])


  const initialValues = {
    reason: "",
    amount: 0,
    student_id: null,
  }

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required(),
    amount: Yup.number().required().max(10000),
    student_id: Yup.number().required(),
  })

  const submitValues = (values) => {
    handleClose()
    setOption(null)
    setSelectedStudent(null)
    formikRef.current.resetForm()
    handleConfirm({...values, plus: typeof values?.plus !== "boolean"?  parseFloat(values?.amount) > 0: values?.plus})
  }

  const handlePresetClick = (preset) => {
    formikRef.current.setValues({
      ...formikRef.current.values,
      reason: preset?.reason,
      amount: preset?.amount,
      plus: preset?.is_addition,
    })

    const amountInput = document.querySelector('input[name="reward"]');
    if (amountInput) {
      amountInput.focus();
    }
  }

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      height="auto"
      closeOnClickOutside={false}
      heightContainer="100%"
      title={"Random Call"}
      handleClose={() => {
        handleClose()
        setOption(null)
        setSelectedStudent(null)
      }}
      widthContainer={'100%'}
      px={0}
    >
      <MDBox width={"100%"} display={"flex"} flexDirection={"column"} gap={3}>
        <Grid item justifyContent={'center'} xs={12} columnGap={2} wrap={'nowrap'} container>
          <MDButton
            variant="contained"
            color={"primary"}
            onClick={() => setOption("with_replacement")}
          >
            <Typography fontWeight={700} fontSize={{xs: 10, md: 14}}>With replacement</Typography>
          </MDButton>
          <MDButton
            variant="contained"
            color={"primary"}
            onClick={() => setOption("without_replacement")}
          >
            <Typography fontWeight={700} fontSize={{xs: 10, md: 14}}>Without replacement</Typography>

          </MDButton>
          <MDButton
            variant="contained"
            color={"primary"}
            onClick={() => setOption("reset")}
          >
            <Typography fontWeight={700} fontSize={{xs: 10, md: 14}}>Reset</Typography>

          </MDButton>
        </Grid>
          <MDBox>
            <Grid container justifyContent={'space-between'} >
              <Grid item xs={12} md={5.5} mb={1} >
                <Formik
                  innerRef={formikRef}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnBlur={false}
                  onSubmit={(values) => {
                    submitValues(values)
                    formikRef.current.resetForm()
                  }}
                  checked={true}
                >
                  {({ handleSubmit, isValid, dirty }) => {
                    return (
                      <Form style={{ width: "100%" }}>
                        <Grid container >
                          <Grid item xs={12} display={'flex'} flexDirection={'column'} rowGap={1}>
                            <Grid display={'flex'} alignItems={'center'} gap={2} >
                              {imageProfile
                                ? <Box component={"img"} src={imageProfile} alt={"profile_picture"} width={"80px"} height={"80px"}
                                       sx={{objectFit: 'cover'}} borderRadius={"50%"}/>
                                : <Box component={"img"} src={defaultImg} alt={"profile_picture"} width={"80px"} height={"80px"}
                                       borderRadius={"50%"}/>
                              }
                              {selectedStudent ?
                                <MDBox display={"flex"} flexDirection={"column"} width={"100%"}>
                                  <Typography color={"secondary"} fontWeight="bold" fontSize={18} >
                                    {selectedStudent?.name}
                                  </Typography>

                                  <MDBox display={"flex"}justifyContent={'space-between'}>
                                    <Typography color={"secondary"} fontWeight="bold" fontSize={15} >
                                      Total amount:
                                    </Typography>
                                    <MDBox display={"flex"} alignItems={"center"}>
                                      <Typography  color={"secondary"}  fontWeight="bold" fontSize={15} sx={{wordBreak: "break-word",}}>
                                        {selectedStudent?.balance}
                                      </Typography>
                                      <PaidIcon color={"primary"}/>
                                    </MDBox>
                                  </MDBox>
                                </MDBox>
                                :
                                <Typography  color={"error"}  fontWeight="bold" fontSize={12} sx={{wordBreak: "break-word",}}>
                                  Please choose "whith replacement" or "without replacement" option
                                </Typography>
                              }

                            </Grid>
                            <FormikInput
                              name="reason"
                              rows={3}
                              type={"textarea"}
                              label="Reason"
                            />
                            <FormikInput
                              name="amount"
                              type={"number"}
                              label="Amount"
                            />
                          </Grid>
                          <Grid item xs={12} display="flex">
                            <MDButton
                              color="primary"
                              onClick={handleSubmit}
                              disabled={!isValid || !dirty}
                            >
                              {"Apply"}
                            </MDButton>
                          </Grid>
                        </Grid>
                      </Form>
                    )
                  }}
                </Formik>
              </Grid>
              <Grid item container xs={12} md={6} alignContent={'flex-start'} overflow={'hidden'} justifyContent={'center'} borderRadius={3}  maxHeight={"60vh"}>
                <Grid item justifyContent={'center'} xs={12} columnGap={2} px={1} wrap={'nowrap'} container>
                  <MDButton onClick={() => handleFilterClick('all')}>All</MDButton>
                  <MDButton onClick={() => handleFilterClick('positive')}>Positive</MDButton>
                  <MDButton onClick={() => handleFilterClick('negative')}>Negative</MDButton>
                </Grid>

                <Grid bgcolor={'#E5F9DB'} container justifyContent={'space-between'} overflow={'auto'} spacing={'4px'} mt={2} p={1} borderRadius={3}  height={{xs:'200px', md:'400px', lg:'400px'}}>
                  {filteredSuggestions().map((preset, index) => (
                    <Grid
                      item xs={12} md={6}
                      maxHeight={'100px'}
                      key={`buck-preset-${preset.id}`}
                    >
                      <DiscussionSuggestionsCard
                        title={preset?.reason}
                        amount={preset?.amount}
                        isAddition={preset?.is_addition}
                        onClick={() => handlePresetClick(preset)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
      </MDBox>
    </ModalItem>
  )
}

export default RandomCallModal
