import React, {useEffect, useRef, useState} from "react";
import MDTypography from "components/MDTypography";
import {Form, Formik} from "formik";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FormikInput from "../../components/FormikInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { makeStyles } from "@mui/styles";
import draftToHtml from "draftjs-to-html";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import UploadIcon from "@mui/icons-material/Upload";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {MODALITY_OPTIONS} from "../../utils/helpers";
import {ColorPicker, createColor} from 'mui-color';
import "./editorStyles.css"
import {Box, Checkbox, Grid} from "@mui/material";
import ImageUploader from "../../components/ImageUploader";
import {useApi} from "../../services/helpers";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import {SelectMenuProps} from "../../services/constants";

const useStyles = makeStyles((theme) => {
  return {
    summary: { border: `1px solid ${theme.palette.grey[200]}`, borderRadius: 8 },
    editor: { height: "400px", fontSize: "14px", margin: "20px"},
  };
});

const CourseProfile = ({ loading, onCreate, onUpdate, courseData, setIsEditing }) => {
  const api = useApi();
  const formikRef = useRef();
  const styles = useStyles();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(courseData?
    () => {
      const blocksFromHTML = convertFromHTML(courseData?.summary)
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      return EditorState.createWithContent(contentState)
    }
    :
    EditorState.createEmpty()
  )
  const [color, setColor] = useState(createColor(courseData?.color || "#6A994E")); // Primary color
  const [gradeLevels, setGradeLevels] = useState(courseData?.grade_levels?.map(({id}) => id) || []);
  const [allGradeLevels, setAllGradeLevels] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    modality: Yup.string().required(),
    location: Yup.string().required(),
    summary: Yup.string().required(),
  });


  const initialValues = {
    name: courseData?.name || "",
    modality: courseData?.modality || MODALITY_OPTIONS.VIRTUAL,
    location: courseData?.location || "",
    summary: courseData?.summary || "",
  };

    const handleGradesChange = (event) => {
        if (event.target.value.includes(true)) {
            setGradeLevels(allGradeLevels.map(({id}) => id).filter(id => id !== null && id !== true));
        } else if (event.target.value.includes(null)) {
            setGradeLevels([]);
        }else {
            setGradeLevels(event.target.value.sort());
        }
    }

    useEffect(() => {
        api.getGradeLevels().handle({
            onSuccess: ({response}) => { setAllGradeLevels([
                {id: true, name: "All"},
                {id: null, name: "None"},
                ...response
            ]) },
            errorMessage: "Error getting grade levels",
        })
    }, []);

  return (
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(values) =>
          courseData?
            onUpdate({...values, color: `#${color.hex}`, grade_levels: gradeLevels})
            : onCreate({...values, color: `#${color.hex}`, grade_levels: gradeLevels})
        }
      >
        {({ errors, setFieldValue }) => (
          <Form style={{ display: "flex", flexDirection: "column", flex: 1 }}>

            <Grid container>
              <Grid item container xs={12} direction={'row'}>
              <Grid item xs={12} sm={3} sx={{borderRight: "1px solid rgba(0, 0, 0, 0.25)", mt:'8px', pr:2 }} >
                <MDTypography color="primary" fontSize={{xs:'18px', md:"20px"}}  mt={{xs:1, sm:3}}>
                  Course Title
                </MDTypography>
                <FormikInput
                  name="name"
                  placeholder="Title Course"
                  errors={errors}
                />
                <MDTypography color="primary" fontSize={{xs:'18px', md:"20px"}}  mt={{xs:-1, sm:3}}>
                  Modality
                </MDTypography>
                <RadioGroup
                  defaultValue={courseData?.modality || MODALITY_OPTIONS.VIRTUAL}
                  row
                  name="row-radio-buttons-group"
                  onClick={(e) => setFieldValue("modality", e.target.value)}
                >
                  {Object.entries(MODALITY_OPTIONS._LABELS).map(([key, label]) => (
                    <FormControlLabel
                      key={key}
                      value={key}
                      control={<Radio />}
                      label={label}
                    />
                  ))}
                </RadioGroup>
                <MDTypography color="primary" fontSize={{xs:'18px', md:"20px"}}  mt={{xs:0, sm:3}}>
                  Location
                </MDTypography>
                <FormikInput
                  name="location"
                  placeholder="Location"
                  errors={errors}
                />
                <MDTypography color="primary" fontSize={{xs:'18px', md:"20px"}}  mt={{xs:-1, sm:3}}>
                  Course Image
                </MDTypography>
                <ImageUploader onImageUpload={(image) => setFieldValue("image", image)} picture={courseData?.image} />
                <MDTypography color="primary" fontSize={{xs:'18px', md:"20px"}}  mt={{xs:1, sm:3}}>
                  Course Color
                </MDTypography>
                <ColorPicker
                  sx={{width: "20px"}}
                  value={color}
                  onChange={(value) => setColor(value)}
                  disableAlpha={true}
                  inputFormats={["hex", "rgb"]}
                />
                <MDTypography color="primary" fontSize={{xs:'18px', md:"20px"}}  mt={{xs:1, sm:3}}>
                  Course Grade levels
                </MDTypography>
                  <Select
                      multiple
                      value={gradeLevels}
                      onChange={handleGradesChange}
                      input={<OutlinedInput
                          sx={[{
                              width: "100%",
                              minHeight: "45px",
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              color: "#fff",
                              border: "1px solid rgb(219, 219, 219)",
                              padding: {
                                  xs: '7px'
                              },
                          }]}
                      />}
                      renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => {
                                  const found = allGradeLevels.find(({id}) => id === value);
                                  return !!found ? (
                                  <Chip
                                      key={value}
                                      label={found.sn}
                                      color={"primary"}
                                  />
                              ): null})}
                          </Box>
                      )}
                      fullWidth
                      MenuProps={SelectMenuProps}
                  >
                      {allGradeLevels.map((value) => (
                          <MenuItem
                              key={value.name}
                              value={value.id}
                          >
                              <Checkbox
                                  checked={gradeLevels.includes(value.id) ||
                                      (value.id == null && gradeLevels.length === 0) ||
                                      (value.id === true && gradeLevels.length === allGradeLevels.length - 2) } />
                              {value.name}
                          </MenuItem>
                      ))}
                  </Select>
              </Grid>
              <Grid item xs={12} sm={9} sx={{pl:{sx:0, sm:2}}}>

                <MDTypography color="primary" fontSize={{xs:'18px', md:"20px"}}  mt={{xs:1, sm:3}}>Course Summary</MDTypography>

                <Editor
                  editorState={editorState}
                  wrapperClassName={styles.summary}
                  onEditorStateChange={(state) => {
                    setFieldValue("summary", draftToHtml(convertToRaw(editorState.getCurrentContent())));
                    setEditorState(state);
                  }}
                  editorClassName={styles.editor}
                />

              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} justifyContent={'flex-end'}>
            <MDBox>
              <MDBox display="flex" justifyContent="flex-end" my={4}>
                <MDButton color="primary" type="submit" sx={{ mr: 2 }} loading={loading} disabled={loading}>
                  Save and display
                </MDButton>
                <MDButton onClick={() => courseData? setIsEditing(false): navigate(-1)}>Cancel</MDButton>
              </MDBox>
            </MDBox>
            </Grid>
            </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
  );
};

export default CourseProfile;
