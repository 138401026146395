import { useRef, useState } from "react"
import * as Yup from "yup"
import ModalItem from "../../components/ModalItem"
import MDBox from "../../components/MDBox"
import { Form, Formik } from "formik"
import { Grid } from "@mui/material"
import MDTypography from "../../components/MDTypography"
import FormikInput from "../../components/FormikInput"
import MDButton from "../../components/MDButton"
import MDBuckPresetCard from "./components/MDBuckPresetCard"

export const useManualBucksModal = ({ sendBucks, selectorOptions, defaultSelector, bucksPresets }) => {
  const formikRef = useRef()
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState({})
  const { studentName, plus } = data
  const title = plus ? `To: ${studentName}` : `From: ${studentName}`

  const initialValues = {
    message: "",
    selector: defaultSelector,
  }

  const validationSchema = Yup.object().shape({
    selector: Yup.object(),
    message: Yup.string().required(),
    amount: Yup.number().required().min(1).max(10000),
  })

  const showModalFn = (data) => {
    setData(data)
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const submitValues = (values) => {
    setShowModal(false)
    formikRef.current.resetForm()
    sendBucks(data, values)
  }

  const handlePresetClick = (preset) => {
    formikRef.current.setValues({
      ...formikRef.current.values,
      message: preset.reason,
      amount: preset.amount,
    })
    //
    const amountInput = document.querySelector('input[name="amount"]');
    if (amountInput) {
      amountInput.focus();
    }
  }

  const jsxNode = (
    <ModalItem
      scrollable={false}
      open={showModal}
      height="auto"
      widthContainer={'100%'}
      closeOnClickOutside={false}
      heightContainer="100%"
      title={plus ? "Credit bucks" : "Debit bucks"}
      handleClose={handleClose}
      px={0}
    >
      <MDBox display={"flex"} flexDirection={"column"} width={"100%"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} marginBottom={1} >
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur={false}
              onSubmit={(values) => {
                submitValues(values)
                formikRef.current.resetForm()
              }}
              checked={true}
            >
              {({ handleSubmit, isValid, dirty }) => {
                return (
                  <Form style={{ width: "100%" }}>
                    <Grid container rowGap={"25px"} >
                      <Grid item xs={12} mt={3}>
                        {selectorOptions ? (
                          <FormikInput
                            name="selector"
                            type={"select"}
                            placeholder="Students"
                            initialValue={defaultSelector}
                            options={selectorOptions}
                            errors={{}}
                            disableClearable
                          />
                        ) : (
                          <MDTypography color="dark" fontSize={{ xs: 18, md: 24 }} flexDirection="row" mr={2} mb={2}>
                            {title}
                          </MDTypography>
                        )}

                        <FormikInput
                          name="message"
                          rows={2}
                          type={"textarea"}
                          label="Reason"
                        />
                        <FormikInput
                          name="amount"
                          type={"number"}
                          label="Amount"
                        />
                      </Grid>
                      <Grid item xs={12} mt={1} display="flex" justifyContent="center">
                        <MDButton
                          color="primary"
                          onClick={handleSubmit}
                          disabled={!isValid || !dirty}
                        >
                          {plus ? "Add" : "Deduct"}
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Grid>
          {bucksPresets?.length > 0 &&
            <Grid item container xs={12} md={6}  spacing={1} justifyContent={'space-between'} overflow={"auto"} maxHeight={{xs:"30vh", md:"60vh"}}>
              {bucksPresets.map((preset, index) => (
                <Grid  item xs={12} md={5.5} key={`buck-preset-${preset.id}`}>
                  <MDBuckPresetCard
                    title={preset.reason}
                    amount={preset.amount}
                    isAddition={preset.is_addition}
                    onClick={() => handlePresetClick(preset)}
                  />
                </Grid>
              ))}
            </Grid>
          }
        </Grid>
      </MDBox>
    </ModalItem>
  )

  return [jsxNode, showModalFn]
}
