export const ROLES = {
  ADMIN: { id: 1, name: 'Admin' },
  PROJECT_MANAGER: { id: 2, name: 'Project Manager' },
  SUPPLY_MANAGER: { id: 3, name: 'Supply Manager' },
}

export const API_VERSION_PREFIX = '/api/v1'

export const ROUTES = {
  ROOT: '/',
  LOGOUT: '/logout',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  PROFILE: '/profile',
  EARNING_HISTORY: '/earning-history',
  NOT_FOUND: '/404',
  COURSES: '/courses',
  NEW_COURSE: '/courses/new',

  SET_NEW_PASSWORD: '/set-new-password/*',
  ACTIVATE_USER: '/activate-user/*',
  // Project manager routes
  USER_CHANGE_PASSWORD: `/change-password`,
  USER_TERMS_AND_CONDITIONS: `/terms-and-conditions`,
  USER_PRIVACY_POLICY: `/privacy-policy`,
  USER_ACCOUNT_SETTINGS: `/account-settings`,
  GAME_VIEW: (id) => `/game-view/${id}`,
  QR_READER: `/qr-reader`,
  PRODUCT_MANAGEMENT: (id) => `/update-product/${id}`,
  CREATE_NEW_PRODUCT: `/new-product`,
  PRODUCT_DETAIL: (id) => `/product-detail/${id}`,
  MARKETPLACE: `/marketplace`,

  // Admin routes
}

export const CONNECTED_FLAG = 'connected'
export const THERMOMETER = 'thermometer'
export const BARS = 'bars'

export const MEETING_ACTIONS = {
  START: 'start',
  END: 'end',
  PAUSE: 'pause',
  RESUME: 'resume',
}

export const STUDENT_FLAGS = {
  BATHROOM: 1,
  FLAG: 2,
  RAISE_HAND: 3,
  // are these flags?
  THUMBS_UP: 4,
  THUMBS_DOWN: 5,
}

export const STUDENT_FEEDBACK = {
  THUMBS_UP: 1,
  THUMBS_DOWN: 2,
}

export const QUESTION_TYPES = {
  OPEN: 1,
  MULTIPLE_CHOICE: 2,
}

export const GAME_MESSAGE_TYPES = {
  CORRECT_RESPONSE: 1,
  INCORRECT_RESPONSE: 2,
  DEDUCTION: 3,
  EXTRA_REWARD: 4,
  CORRECT_ESTIM_RESPONSE: 5,

}

export const METRICS = {
  TS_Q_RECEIVED: 1,
  TS_ENGAGEMENT_GIVEN: 2,
  TS_Q_ANSWERED: 3,
  ENGAGEMENT_CORRECT: 'engagement_correct',
}

export const CLASS_MODES = {
  QUESTIONS: 'questions',
  TASKS: 'tasks',
  DISCUSSION: 'discussion',
}

export const ice_servers = 'stun:stun4.l.google.com:19302'

export const rtcConfig = {
  // iceServers: [
  //     {  urls: ice_servers  }
  // ]
}

export const STUDENT_TASK_STATUSES = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
}

export const TASK_DEPENDECY_TYPES = {
  NO_DEPENDENCY: 0,
  ON_SUBMIT: 1,
  ON_APPROVAL: 2,
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
