import {Box, Card, CardActionArea, CardActions, IconButton, Stack, Typography} from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import PaidIcon from "@mui/icons-material/Paid";
import React, {useEffect, useState} from "react";
import MDBox from "../../../components/MDBox";
import defaultImg from "../../../assets/images/default-profile.png";
import vector2 from "../../../assets/icons/Vector-2.svg";
import vector1 from "../../../assets/icons/Vector-1.svg";
import vector from "../../../assets/icons/Vector.svg";
import group from "../../../assets/icons/Group.svg";
import MDButton from "../../../components/MDButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CardContent from "@mui/material/CardContent";
import {useApi} from "../../../services/helpers";
import {useParams} from "react-router-dom";

const DiscussionStudentCard = ({ student, openModal, renderStudentBalance, onClickDirectAccess}) => {
  const { courseId, meetingId } = useParams()
  const api = useApi()
  const [directsValues, setDirectsValues] = useState(undefined);
  const directAccessInteractions = () => {
    api.directAccessInteractions(meetingId).handle({
      onSuccess: (res) => {
        setDirectsValues(res.data)
      },
      errorMessage: "Error getting discussions",
    })
  }

  const formatDataBeforeSending = (key) => {
    return {
      student,
      action: directsValues?.[key][0],
      reward: directsValues?.[key][1]
    }
  }

  useEffect(() => {
    directAccessInteractions()
  }, [])

  return (
    <Card
      sx={{ m:1, minWidth:'300px' }}
    >
      <CardContent sx={{display:"flex", flex:1, p:"0 !important", justifyContent:"space-between"}}>
          <MDBox sx={{width:'100%'}}>
          <MDBox display={"flex"} alignItems={"center"} pt={1} pl={1}>
            {student?.image
              ? <Box component={"img"} src={student?.image} alt={"profile_picture"} height={"70px"} width={"70px"}
                     sx={{objectFit: 'cover'}} borderRadius={"50%"}/>
              : <Box component={"img"} src={defaultImg} alt={"profile_picture"} height={"70px"} width={"70px"}
                     borderRadius={"50%"}/>
            }
            <MDBox display={"flex"} flexDirection={"column"} alignItems={"end"}>
              <Typography sx={{}} maxWidth={'150px'} color={"secondary"} fontWeight="bold" fontSize={{xs:'14px', md:'18px'}} >
                {student?.name}
              </Typography>
              <MDBox display={"flex"}  alignItems={"center"}>
                <Typography  color={"secondary"}  fontWeight="bold" fontSize={15} sx={{wordBreak: "break-word",}}>
                  {renderStudentBalance(student)}
                </Typography>
                <PaidIcon color={"primary"}/>
              </MDBox>
            </MDBox>
          </MDBox>
          <CardActions sx={{justifyContent:"space-between"}}>
            <MDButton
                size={"small"}
                minWidthButton={{xs:'42px', md:"64px"}}
              variant="contained"
              onClick={() => {
                onClickDirectAccess(formatDataBeforeSending("original_idea"))
              }}
            >
              <MDBox>
                <img src={group} alt="original_idea"/>
                <MDTypography
                  variant="caption"
                  color={"primary"}
                  fontWeight="bold"
                >
                  {directsValues?.["original_idea"][1]}
                </MDTypography>
              </MDBox>
            </MDButton>

            <MDButton
                size={"small"}
                minWidthButton={{xs:'42px', md:"64px"}}
              variant="contained"
              onClick={() => onClickDirectAccess(formatDataBeforeSending("asked_question"))}
            >
              <MDBox>
              <img src={vector} alt="asked_question"/>
              <MDTypography
                variant="caption"
                color={"primary"}
                fontWeight="bold"
              >
                {directsValues?.["asked_question"][1]}
              </MDTypography>
              </MDBox>
            </MDButton>

            <MDButton
                size={"small"}
                minWidthButton={{xs:'42px', md:"64px"}}
              variant="contained"
              onClick={() => onClickDirectAccess(formatDataBeforeSending("answered_question"))}
            >
              <MDBox>
                <img src={vector1} alt="answered_question"/>
                <MDTypography
                  variant="caption"
                  color={"primary"}
                  fontWeight="bold"
                >
                  {directsValues?.["answered_question"][1]}
                </MDTypography>
              </MDBox>
            </MDButton>

            <MDButton
                size={"small"}
                minWidthButton={{xs:'42px', md:"64px"}}
              variant="contained"
              onClick={() => onClickDirectAccess(formatDataBeforeSending("distraction"))}
            >
                <MDBox>
                  <img src={vector2} alt="distraction"/>
                  <MDTypography
                    variant="caption"
                    color={"error"}
                    fontWeight="bold"
                  >
                    {directsValues?.["distraction"][1]}
                  </MDTypography>
                </MDBox>
            </MDButton>
          </CardActions>
        </MDBox>
        <MDBox
          sx={{ cursor: "pointer"}}
          bgColor={"primary"}
          width={'52px'}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          borderRadius={"4px"}
          onClick={() => openModal(student)}
        >
          <IconButton color="white" size={"large"}>
            <AddRoundedIcon />
          </IconButton>
        </MDBox>
      </CardContent>
    </Card>
  )
}

export default DiscussionStudentCard
