import {ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight} from "@mui/icons-material"
import {useMediaQuery, useTheme} from "@mui/material"
import {setHideNavbar, useMaterialUIController} from "context"
import MobileDetect from "mobile-detect"
import React, {useEffect, useRef, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {
    BARS,
    CLASS_MODES,
    GAME_MESSAGE_TYPES,
    METRICS,
    ROUTES,
    STUDENT_FLAGS,
    THERMOMETER,
} from "../../services/constants"
import {vibrate} from "../../services/haptic_service"
import {showMessage, useApi} from "../../services/helpers"
import {RealtimeContext} from "../../services/streaming/provider"
import {WakeLockContext} from "../../services/wakelock_service"
import {TYPE_OF_MOMENT_POPUP, useLoginStore, useScreenOrientation} from "../../utils/helpers"
import {HorizontalGameboyView} from "./horizontalView"
import {LaptopFrame} from "./laptop_frame"
import {GameboyView} from "./verticalView"
import useTaskImageUpload from "../../utils/useTaskImageUpload"

const logo = require("../../assets/images/logos/logo.png")

const noop = () => {
}

const keyMappings = {
    1: "KeyW",
    3: "KeyA",
    4: "KeyD",
    2: "KeyS",
    7: "ArrowLeft",
    8: "ArrowUp",
    6: "ArrowRight",
    5: "ArrowDown",
}

const nullOption = {
    id: -1,
    color: null,
    action: null,
    button: null,
    text: null,
}

const XZBA_OPTIONS_TPL = [
    {
        id: 7,
        text: "I totally understand",
        button: "X",
        color: "#FEF200",
        action: noop,
    },
    {
        id: 8,
        text: "I mostly understand",
        button: "Y",
        color: "#00B447",
        action: noop,
    },
    {
        id: 6,
        text: "I mostly don't understand",
        button: "B",
        color: "#010DFF",
        action: noop,
    },
    {
        id: 5,
        text: "I don't understand at all",
        button: "A",
        color: "#FF2D3A",
        action: noop,
    },
]

const ARROW_OPTIONS_TPL = [
    {
        id: 1,
        text: "I totally understand",
        button: <ArrowDropUp style={{color: "#BBB9B9", width: "100%", height: "100%"}}/>,
        color: "#6A994E",
        action: noop,
    },
    {
        id: 3,
        text: "I totally understand",
        button: <ArrowLeft style={{color: "#BBB9B9", width: "100%", height: "100%"}}/>,
        color: "#6A994E",
        action: noop,
    },
    {
        id: 4,
        text: "I totally understand",
        button: <ArrowRight style={{color: "#BBB9B9", width: "100%", height: "100%"}}/>,
        color: "#6A994E",
        action: noop,
    },
    {
        id: 2,
        text: "I totally understand",
        button: <ArrowDropDown style={{color: "#BBB9B9", width: "100%", height: "100%"}}/>,
        color: "#6A994E",
        action: noop,
    },
]

const GameView = () => {
    const loginStore = useLoginStore()
    const theme = useTheme()
    const navigate = useNavigate()
    const [mobileDetect] = useState(new MobileDetect(navigator.userAgent))
    const isMobilePortrait = useMediaQuery(theme.breakpoints.down("sm"))
    const [laptopOrientation, setLaptopOrientation] = useState(true)
    const [isMobile, setIsMobile] = useState(mobileDetect.mobile() !== null)
    const screenOrientation = useScreenOrientation()
    const [answer, setAnswer] = useState(4)
    const width = window.innerWidth
    const height = window.innerHeight

    const [XZBA_OPTIONS, setXZBA_OPTIONS] = useState(XZBA_OPTIONS_TPL)
    const [ARROW_OPTIONS, setARROW_OPTIONS] = useState(ARROW_OPTIONS_TPL)

    const {meetingId} = useParams()
    const [meeting, setMeeting] = useState({})
    const [balance, setBalance] = useState(null)
    const [flags, setFlags] = useState(
        Object.fromEntries(Object.values(STUDENT_FLAGS).map((flag) => [flag, false])),
    )
    const [engagementAnswered, setEngagementAnswered] = useState(null)
    const [messageShowing, setMessageShowing] = useState(null)
    const [wbqMessageShowing, setWbqMessageShowing] = useState(null)
    const qTimerRef = React.useRef(null)
    const metricsRef = React.useRef({})
    const [loading, setLoading] = useState(false)
    const [classMode, setClassMode] = useState(CLASS_MODES.QUESTIONS)
    const [controller, dispatch] = useMaterialUIController()
    const api = useApi()
    const {enableWebsocket, subscribe, addMessageListener, removeMessageListener, sendMeetingId} =
        React.useContext(RealtimeContext)
    const {requestWakeLock, releaseWakeLock} = React.useContext(WakeLockContext)
    const [latestQuestion, setLatestQuestion] = useState(null)
    const [wbQuestions, setWBQuestions] = useState([])
    const [wbQuestionAnswered, setWBQuestionAnswered] = useState(false)
    const [WBQuestionView, setWBQuestionView] = useState(false)
    const [isEndOfClass, setIsEndOfClass] = useState(false)
    const [rewardNotification, setRewardNotification] = useState(null)
    const [latestTaskGroup, setLatestTaskGroup] = useState(null)
    const latestTaskGroupRef = useRef(latestTaskGroup)
    const [navigatePenaltyOn, setNavigatePenaltyOn] = useState(true)
    const mode_topic = `meeting/${meetingId}/mode`
    const asks_topic = `meeting/${meetingId}/${loginStore.id}/asks`
    const asks_wb_questions_topic = `meeting/${meetingId}/${loginStore.id}/asks_wb_questions`
    // all students receive same task
    const tasks_topic = `meeting/${meetingId}/tasks`
    // single student receives current task update
    const student_tasks_topic = `meeting/${meetingId}/${loginStore.student.id}/tasks`
    const balance_topic = `student/${loginStore.id}/balance`
    const thermometer_topic = `meeting/${meetingId}/${loginStore.id}/thermometer`
    const bars_topic = `meeting/${meetingId}/${loginStore.id}/bars`
    const end_topic = `meeting/${meetingId}/end`
    const navigatedMessageRef = useRef(null)
    const navigate_penalty_on = `meeting/${meetingId}/navigate_penalty_on`
    const random_call_topic = `meeting/${meetingId}/random_call_reward`;

    const keyPressed = (event) => {
        const key = event.code
        const mapping = Object.entries(keyMappings).find(([id, value]) => value === key)

        if (mapping) {
            userInteraction({button: parseInt(mapping[0])})
        }
    }

    const beforeUnloadHandler = (e) => {
        e.preventDefault()
        e.returnValue = ""
    }

    const userNavigatedAway = (onhide, showMessageOnBack = true) => {
        if (onhide) {
            api.userNavigatedAway(meetingId).handle({
                onSuccess: (res) => {
                    console.log("Navigated away")
                    if (!showMessageOnBack) {
                        showMessage(res.data, "warning")
                    } else {
                        navigatedMessageRef.current = res.data
                    }
                },
            })
        } else {
            if (navigatedMessageRef.current) {
                showMessage(navigatedMessageRef.current, "warning")
                navigatedMessageRef.current = null
            }
        }
    }

    const sendWBExitTicketQuestion = () => {
        api.getWBQuestionsForMeeting(meetingId, {pop_up_moment: TYPE_OF_MOMENT_POPUP.EXIT_TICKET}).handle({
            onSuccess: (res) => {
                setWBQuestionView(true)
                setWBQuestions(res.data)
            },
            // successMessage: "Meeting ended...",
            errorMessage: "Error getting well being questions",
        })
    }

    const visibilityChangeHandler = (e) => {
        userNavigatedAway(document.hidden, true)
    }

    useEffect(() => {
        window.addEventListener("keydown", keyPressed)
        window.addEventListener("beforeunload", beforeUnloadHandler)
        document.addEventListener("visibilitychange", visibilityChangeHandler)
        return () => {
            window.removeEventListener("keydown", keyPressed)
            window.removeEventListener("beforeunload", beforeUnloadHandler)
            document.removeEventListener("visibilitychange", visibilityChangeHandler)
        }
    }, [])

    useEffect(() => {
        document.removeEventListener("visibilitychange", visibilityChangeHandler)
        if (navigatePenaltyOn) {
            document.addEventListener("visibilitychange", visibilityChangeHandler)
        }
        return () => {
            document.removeEventListener("visibilitychange", visibilityChangeHandler)
        }
    }, [navigatePenaltyOn]);

    useEffect(() => {
        setHideNavbar(dispatch, true)
    }, [])

    useEffect(() => {
        requestWakeLock()
        return () => {
            releaseWakeLock()
        }
    }, [releaseWakeLock, requestWakeLock])

    useEffect(() => {
        latestTaskGroupRef.current = latestTaskGroup
    }, [latestTaskGroup])

    useEffect(() => {
        const listener = (message) => {
            if (message.type === "welcome") {
                sendMeetingId(meetingId)
                subscribe(mode_topic)
                subscribe(asks_topic)
                subscribe(asks_wb_questions_topic)
                subscribe(tasks_topic)
                subscribe(student_tasks_topic)
                subscribe(balance_topic)
                subscribe(thermometer_topic)
                subscribe(bars_topic)
                subscribe(end_topic)
                subscribe(navigate_penalty_on)
                subscribe(random_call_topic)
                Object.values(STUDENT_FLAGS).forEach((flag) => {
                    subscribe(`meeting/${meetingId}/${loginStore.id}/flag/${flag}`)
                })
            } else if (message?.type === "message") {
                const topic = message?.topic
                const payload = message?.payload
                // QUESTION RECEIVED
                if (topic === asks_topic) {
                    console.log("Received asks question:", message?.payload)
                    const question = JSON.parse(payload)
                    setEngagementAnswered(null)
                    setLatestQuestion(question)
                    setLatestTaskGroup(null)
                    setMessageShowing(null)
                    setWBQuestionView(false)
                    metricsRef.current = {}
                    metricsRef.current[METRICS.TS_Q_RECEIVED] = +Date.now()

                } else if (topic === asks_wb_questions_topic) {
                    const questions = JSON.parse(payload)

                    // questions? setWBQuestionView(true) : setWBQuestionView(false)
                    setWBQuestionView(true)
                    setWBQuestions(questions)
                    setWbqMessageShowing(null)
                    metricsRef.current = {}
                    metricsRef.current[METRICS.TS_Q_RECEIVED] = +Date.now()
                    // TASK GROUP RECEIVED (all students)
                } else if (topic === tasks_topic) {
                    const taskGroup = JSON.parse(payload)?.task_group || {}
                    getTaskGroup(meetingId, taskGroup.id)
                }
                // TASK GROUP RECEIVED (single student after review)
                if (topic === student_tasks_topic) {
                    const taskGroup = JSON.parse(payload)?.task_group || {}
                    if (latestTaskGroupRef.current?.id === taskGroup.id) {
                        getTaskGroup(meetingId, taskGroup.id)
                    }
                } else if (topic === balance_topic) {
                    setBalance(payload)
                } else if (topic === mode_topic) {
                    setClassMode(message.payload)
                } else if (topic === random_call_topic) {
                    setRewardNotification(JSON.parse(message?.payload));
                } else if (topic === end_topic) {
                    // ### here make changes
                    setIsEndOfClass(true)
                    sendWBExitTicketQuestion()

                } else if (topic === bars_topic) {
                    setFlags((flags) => ({...flags, [BARS]: JSON.parse(payload)}))
                } else if (topic === thermometer_topic) {
                    setFlags((flags) => ({...flags, [THERMOMETER]: JSON.parse(payload)}))
                } else if (/^meeting\/(\d+)\/(\d+)\/flag\/(\d+)$/.test(topic)) {
                    const [, meetingId2, studentId2, flag] = /^meeting\/(\d+)\/(\d+)\/flag\/(\d+)$/.exec(
                        topic,
                    )
                    if (meetingId2 === meetingId && parseInt(studentId2) === loginStore.id) {
                        // console.log("Received flag:", flag, payload);
                        // setFlags(flags.concat(flag))
                        setFlags((flags) => ({...flags, [parseInt(flag)]: JSON.parse(payload)}))
                    } else {
                        // console.log("Received flag for another meeting", meetingId2, meetingId, studentId2, loginStore.id);
                    }
                } else if (topic === navigate_penalty_on) {
                    setNavigatePenaltyOn(payload)
                } else {
                    // console.log("Received unknown message", message);
                }
            }
        }

        addMessageListener(listener)
        enableWebsocket()
        return () => {
            removeMessageListener(listener)
        }
    }, [addMessageListener, asks_topic, enableWebsocket, removeMessageListener, subscribe, asks_wb_questions_topic])

    const userInteraction = ({text, button}) => {
        const currentQuestion = WBQuestionView? {...wbQuestions, question: wbQuestions?.questions?.[0]} : latestQuestion
        // if (!latestQuestion || !latestWBQuestion || loading) return
        if (!currentQuestion || loading) return

        if (text) {
            metricsRef.current[METRICS.TS_Q_ANSWERED] = +Date.now()
            answerQuestion(text, metricsRef.current, engagementAnswered)
        }

        if (button) {
            if (engagementAnswered === null) {
                vibrate(100)
                setEngagementAnswered(button)

                metricsRef.current[METRICS.TS_ENGAGEMENT_GIVEN] = +Date.now()

                if (currentQuestion?.question == null) {
                    sendEngagement(metricsRef.current, button)
                }
            } else {
                // const answer = latestQuestion.question.answers.find((a) => a?.button_type === button)
                const answer = currentQuestion?.question?.answers.find((a) => a?.button_type === button)
                if (answer) {
                    vibrate(100)
                    metricsRef.current[METRICS.TS_Q_ANSWERED] = +Date.now()
                    answerQuestion(button, metricsRef.current, engagementAnswered)
                } else {
                    // boop?
                    // ignore
                    vibrate([100, 200, 100])
                }
            }
        }
    }

    const answerQuestion = (answer, metrics, engagementAnswered) => {
        setLoading(true)
        if (qTimerRef.current) {
            clearTimeout(qTimerRef.current)
            qTimerRef.current = null
        }
        //
        WBQuestionView?
          api
            .sendAnswerWBQuestion(meetingId, {
                question: wbQuestions?.questions?.[0]?.id,
                key: wbQuestions?.questions?.[0].key,
                answer: answer,
                parent: wbQuestions?.questions?.[0].parent,
                engagement_button: engagementAnswered,
                metrics,
            })
            .handle({
                onSuccess: (res) => {
                    const {reward, bonus} = res.data
                    nextWBQuestion()
                    setWbqMessageShowing({
                        type: GAME_MESSAGE_TYPES.INCORRECT_RESPONSE,
                        reward,
                        bonus,
                    })
                    setTimeout(() => {
                        setWbqMessageShowing(null) // TODO better

                    }, 5000)
                },
                errorMessage: "Error sending answer",
                // successMessage: "Answer sent successfully",
                onFinally: () => {
                    setLoading(false)
                },
            })
          :
        api
            .sendAnswer(meetingId, {
                question: latestQuestion?.question?.id,
                key: latestQuestion?.question?.key,
                answer: answer,
                parent: latestQuestion?.parent,
                engagement_button: engagementAnswered,
                metrics,
            })
            .handle({
                onSuccess: (res) => {
                    const {correct, reward, bonus} = res.data
                    setLatestQuestion(null)
                    setMessageShowing({
                        type: correct
                            ? GAME_MESSAGE_TYPES.CORRECT_RESPONSE
                            : GAME_MESSAGE_TYPES.INCORRECT_RESPONSE,
                        reward,
                        bonus,
                    })
                    setTimeout(() => {
                        setMessageShowing(null) // TODO better
                    }, 5000)
                },
                errorMessage: "Error sending answer",
                // successMessage: "Answer sent successfully",
                onFinally: () => {
                    setLoading(false)
                },
            })
    }

    const nextWBQuestion = () => {
        // setWBQuestions({...wbQuestions, questions: wbQuestions?.questions.slice(1)});
        const backUp = wbQuestions
        setWBQuestions(null)
        setWBQuestionAnswered(true)
        // setWBQuestionView(false)
        setTimeout(() => {
            // setWBQuestionView(true)
            setWBQuestionAnswered(false)
            setWBQuestions({...backUp, questions: backUp?.questions.slice(1)});
        }, 5000)
    };

    const sendEngagement = (metrics, engagementAnswered) => {
        setLoading(true)
        api
            .sendEngagement(meetingId, {
                parent: latestQuestion?.parent,
                engagement_button: engagementAnswered,
                metrics,
            })
            .handle({
                onSuccess: (res) => {
                    const {reward, bonus, correct} = res.data
                    setLatestQuestion(null)
                    setMessageShowing({
                        type: correct ? GAME_MESSAGE_TYPES.CORRECT_ESTIM_RESPONSE : GAME_MESSAGE_TYPES.INCORRECT_RESPONSE,
                        reward,
                        bonus,
                    })
                    setTimeout(() => {
                        setMessageShowing(null) // TODO better
                    }, 5000)
                },
                errorMessage: "Error sending answer",
                // successMessage: "Answer sent successfully",
                onFinally: () => {
                    setLoading(false)
                },
            })
    }

    const getTaskGroup = (meetingId, taskGroupId) => {
        api.getTaskGroup(meetingId, taskGroupId).handle({
            onSuccess: (response) => {
                setLatestTaskGroup(response.data)
                setLatestQuestion(null)
            },
            errorMessage: "Error getting tasks",
            onError: (err) => {
            },
        })
    }

    const [submittingTasksLoading, setSubmittingTasksLoading] = useState([])

    const submitTask = (meetingId, taskId, image) => {
        setLoading(true)
        setSubmittingTasksLoading((prevState) => {
            if (!prevState.includes(taskId)) {
                return [...prevState, taskId];
            }
            return prevState;
        });
        //
        api
            .submitTask(meetingId, {
                task: taskId,
                image: image,
            })
            .handle({
                onSuccess: (response) => {
                    const { response: updatedTaskGroup } = response
                    setLatestTaskGroup(updatedTaskGroup);
                },
                errorMessage: "Error submitting task",
                onFinally: () => {
                    setLoading(false)
                    setSubmittingTasksLoading((prevState) => prevState.filter(id => id !== taskId))
                },
            })
    }

    useEffect(() => {
        const currentQuestion = WBQuestionView? {...wbQuestions, question: wbQuestions?.questions?.[0]} : latestQuestion
        // if (latestQuestion) {
        if (currentQuestion) {
            // const {question} = latestQuestion
            const {question} = currentQuestion
            if (question) {
                const {answers} = question
                setXZBA_OPTIONS(
                    XZBA_OPTIONS_TPL.map((option, index) => {
                        const answer = answers.find((a) => a?.button_type === option?.id)
                        const action = () => userInteraction({button: option?.id})
                        //const action = !!answer ? () => answerQuestion(answer?.id) : noop
                        return {
                            ...option,
                            text: answer?.answer || "",
                            action: action,
                            ...(!answer ? nullOption : {}),
                        }
                    }),
                )
                setARROW_OPTIONS(
                    ARROW_OPTIONS_TPL.map((option, index) => {
                        const answer = answers.find((a) => a?.button_type === option?.id)
                        //const action = !!answer ? () => answerQuestion(answer?.id) : noop
                        const action = () => userInteraction({button: option?.id})
                        return {
                            ...option,
                            text: answer?.answer || "",
                            action: action,
                            ...(!answer ? nullOption : {}),
                        }
                    }),
                )
            } else {
            }
            //
            // if (!!latestQuestion.q_timeout) {
            if (!!currentQuestion.q_timeout) {
                if (qTimerRef.current) {
                    clearTimeout(qTimerRef.current)
                }
                qTimerRef.current = setTimeout(() => {
                    WBQuestionView? nextWBQuestion():
                    setLatestQuestion(null)

                // }, latestQuestion.q_timeout * 1000)
                }, currentQuestion.q_timeout * 1000)
            }
        }
        if(isEndOfClass && wbQuestions?.questions?.length === 0){
            navigate(ROUTES.ROOT)
        }
    }, [latestQuestion, wbQuestions, WBQuestionView])

    useEffect(() => {
        if (!meetingId) return

        api.getMeetingInfo(meetingId).handle({
            onSuccess: (res) => {
                setMeeting(res?.data)
                setBalance(res?.data?.balance)
            },
            errorMessage: "Error getting class information",
            onError: (err) => {
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            },
        })
    }, [meetingId])

    useEffect(() => {
        setLatestQuestion(null)
        setLatestTaskGroup(null)
    }, [classMode])

    const gameViewHeight = isMobile ? (isMobilePortrait ? "92vh" : "85vh") : "100%"
    const { taskImages, inputRef: taskImageFileInputRef, handleImageChange } = useTaskImageUpload();

    const context = {
        meeting,
        meetingId,
        balance,
        WBQuestionView,
        setWBQuestionView,
        isWBQuestionPending: !!(wbQuestions?.questions?.length),
        wbQuestionAnswered,
        latestQuestion: WBQuestionView? {question: wbQuestions?.questions?.[0] }: latestQuestion,
        rewardNotification,
        setRewardNotification,
        latestTaskGroup,
        submitTask,
        classMode,
        engagementAnswered,
        prompt: (WBQuestionView? wbQuestions?.questions?.[0]?.prompt : latestQuestion?.question?.prompt) || "",
        reward: (WBQuestionView? wbQuestions?.questions?.[0]?.max_reward : latestQuestion?.question?.max_reward) || null,
        XZBA_OPTIONS,
        ARROW_OPTIONS,
        screenOrientation,
        userInteraction,
        messageShowing: WBQuestionView? wbqMessageShowing: messageShowing,
        flags,
        viewHeight: gameViewHeight,
        taskImages,
        taskImageFileInputRef,
        handleImageChange,
        loading,
        submittingTasksLoading,
    }

    const orientation = isMobile ? isMobilePortrait : laptopOrientation

    const gameView = orientation ? (
        <GameboyView {...context} />
    ) : (
        <HorizontalGameboyView {...context} />
    )

    return isMobile ? (
        gameView
    ) : (
        <LaptopFrame
            orientation={laptopOrientation}
            toggleOrientation={() => {
                setLaptopOrientation(!laptopOrientation)
            }}
        >
            {gameView}
        </LaptopFrame>
    )
}

export default GameView
